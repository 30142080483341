import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Avatar, Box, Container, Grid, Typography } from '@material-ui/core';
import { Link } from 'gatsby-theme-material-ui';
import MemberAvatar from '../ui/MemberAvatar';
import SEO from '../components/layout/seo';

const DiretoriaPage = () => {
  const {
    allDatoCmsMember: { nodes },
  } = useStaticQuery(graphql`
    {
      allDatoCmsMember(
        sort: { fields: memberRole___rolePosition, order: ASC }
      ) {
        nodes {
          id
          memberRole {
            roleName
            rolePosition
          }
          memberName
          memberCompany {
            companyName
            companySlug
          }
          memberAvatar {
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  `);

  const [roles, setRoles] = useState([]);

  const getInitials = (name) => {
    const fullName = name.trim().split(' ');

    const firstName = fullName[0][0];
    const lastName = fullName[fullName.length - 1][0];

    return firstName + lastName;
  };

  useEffect(() => {
    // Extract roles to avoid duplicates
    const getRoles = new Set();
    nodes.map((e) => getRoles.add(e.memberRole.roleName));
    setRoles(Array.from(getRoles));
  }, [nodes]);

  return (
    <>
      <SEO title="Diretoria Alphanorth" />
      <Box p={2}>
        <Container maxWidth="md">
          <Box>
            {roles.map((role, i) => (
              <Box mb={4} key={`role-${i}`}>
                <Box p={2} mb={2} bgcolor="whitesmoke">
                  <Typography variant="button">{role}</Typography>
                </Box>
                <Grid container>
                  {nodes.map((member, j) =>
                    member.memberRole.roleName === role ? (
                      <Grid
                        item
                        md={6}
                        key={`member-${j}`}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          margin: '1rem 0',
                        }}
                      >
                        {!member.memberAvatar ? (
                          <Avatar
                            style={{
                              background: 'green',
                              width: '60px',
                              height: '60px',
                            }}
                          >
                            {getInitials(member.memberName)}
                          </Avatar>
                        ) : (
                          <Box className="image">
                            <MemberAvatar fluid={member.memberAvatar.fluid} />
                          </Box>
                        )}

                        <Box p={2}>
                          <Typography variant="h6">
                            {member.memberName}
                          </Typography>
                          <Link
                            to={`/empresas/${member.memberCompany.companySlug}`}
                          >
                            {member.memberCompany.companyName}
                          </Link>
                        </Box>
                      </Grid>
                    ) : null
                  )}
                </Grid>
              </Box>
            ))}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default DiretoriaPage;
