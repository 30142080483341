import { Box } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';

const MemberAvatarStyled = styled(Box)`
  .gatsby-image-wrapper {
    width: 100px;
    border-radius: 50%;
  }
`;

const MemberAvatar = ({ fluid }) => (
  <MemberAvatarStyled>
    <Img fluid={fluid} alt="" />
  </MemberAvatarStyled>
);

MemberAvatar.propTypes = {
  fluid: PropTypes.object,
};

export default MemberAvatar;
